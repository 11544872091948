/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM303M
 * 화면설명: 메인
 * 접근권한: FC
 * 작 성 일: 2022.06.27
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container class="msp" title="New Page" :show-title="false" @on-scroll-top="fn_OpenBs1" @on-scroll-bottom="fn_LoadingData" ref="page" type="subpage" :topButton="false" :topButtonBottom="topButtonBottom" @on-scroll="fn_OnPageScrollEvent">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
       <div class="ns-sliding-topbar" >
          <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="collapsingHeaderView">
        <!-- 상단고정 Tab UI -->
            <!-- 헤더 타이틀 영역 -->
            <template #fixed="{scrollRate}">
              <mo-top-bar color="primary" :scroll-target="scrollElementId" scroll-shadow>
                <div slot="nav" class="icon-wrapper" >
                    <mo-icon color="white" icon-size="36px" borderless @click="fn_HistoryBack">drawer</mo-icon>
                </div>
                <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '프리미엄고객사랑서비스' : '프리미엄고객사랑서비스'}}</div>
                <div slot="action" @click="fn_OpenMSPCM116P()" ><mo-icon >search</mo-icon> </div><!-- icon-size="36px" -->
               </mo-top-bar>
            </template>
            <!-- 헤더 타이틀 영역 //-->
             <!-- collapsing 영역 -->
              <template #collapsing>
              <div style="height: 1px"></div>
            </template>
            <!-- stickey 영역 --> 
            <template #sticky>
              <mo-tab-box default-idx="1" primary class="ns-move-tab-box">
                <!-- tab id loop -->
                <mo-tab-label class="on" idx="1">서비스신청</mo-tab-label>
                <mo-tab-label idx="2" class="off" @click="fn_MoveTabMSPCM310D()" :style="isSuccessService ? 'pointer-events:auto':'pointer-events:none'">활동현황</mo-tab-label>
                <!-- tab id loop //-->
              </mo-tab-box> 
            </template>
            <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" >
             <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="con-area">
                <!--month-->
                <ur-box-container alignH="center" componentid="ur_box_container_001" direction="row" class="point-date-wrap mt30"> 
                  <mo-month-picker class="full" v-model="searchDate" placeholder="2022-12" @confirmed="fn_DateSelected" />
                </ur-box-container>
                <!--//month-->
                 <div class="attention-tip mt10" v-if="attTip1" > 
                  <mo-icon icon="msp-attention" class="att-icon">msp-attention</mo-icon>
                  <p class="att-txt ml5 fs16rem">{{unVstCustFgrYm.substr(4,2)}}월 방문결과 미입력고객 <span class="fwb"> {{unVstCustFgr}}</span>명이 있습니다.</p>
                   <mo-icon icon="msp-close-bold" class="att-btn-close" @click="closeTip(!attTip1)">msp-close-bold</mo-icon>
                </div> 
                <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="mt20">
                  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="row" class="btn-wrap-row">
                    <div class="ns-select-box ns-btn-round ns-btn--checked" :class="{'blue':!isButtonSlct}">
                      <msp-bottom-select ref="bottomSelect" :items="filterSelectItemList" v-model="filterSelectValue" :itemValue="'value'" :itemText="'label'" :itemHeight="374" underline class="ns-dropdown-sheet"  placeholder="회사제공" bottom-title="고객유형" closeBtn @input="fn_CustTypeSelected"/>
                    </div>    
                    <mo-button componentid="mo_button_002"  color="primary" :class="{'blue':isButtonSlct}" shape="border" size="medium" class="ns-btn-round" @click="fn_myCustAdd">내고객 추가</mo-button>                  
                  </ur-box-container>
                </ur-box-container>
              </ur-box-container>

                <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum mt10" >
                <mo-list-item>
                  <mo-checkbox v-model="isOneOrMoreCheck" :disabled="CMCustSrvcObjInfoVOList.length === 0 || currentCnsltInfo.userId !== cnsltInfo.userId" @input="fn_CheckboxAllClick()"></mo-checkbox>
                    <div v-if="checkedCnt === 0" class="list-item__contents"  id="allCnt">
                      <div class="list-item__contents__title" id="nReqCnt">
                        <span class="sum">총 <strong class="crTy-blue3">{{ CMCustSrvcObjInfoVOList.length}}</strong> 명</span>
                      </div>
                    </div>
                    <div v-else class="list-item__contents" id="pCnt">
                      <div class="list-item__contents__title">
                        <span class="sum" ><strong class="crTy-blue3" id="chkedCnt" >{{ checkedCnt }}</strong> 명 선택</span>
                      </div>
                    </div>
                   <span class="mr10">신청완료</span><!--###신청완료-->
                  <mo-switch v-model="applyEnd" small class="ns-switch" />
                </mo-list-item>
              </ur-box-container>
            
             <div class="full">
               <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-list pcls-list pb50">
                <mo-list  :list-data="CMCustSrvcObjInfoVOList">
                  <template #list-item="{index, item}">
                    <mo-list-item   prevent-expand class="bar" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                      <mo-checkbox v-model="item.checked" :disabled="currentCnsltInfo.userId !== cnsltInfo.userId" @input="fn_CheckboxClick()" :ref="`chk${index}`" />
                      <div class="list-item__contents">
                        <div class="list-item__contents__title mb10 fexTy3">
                          <span class="name txtSkip mrauto" :class="item.chnlCustId.trim() === '' ? 'need-cs-card' : ''" @click.stop="fn_OpenCustInfoBs(item,item.chnlCustId)">{{item.custNm}}</span>
                          <span class="fc-badge-wrap">
                            <mo-badge  class="badge-sample-badge green sm" v-if="fn_GetYn(item.custSrvcAplYmd) === 'Y'" text="신청완료" shape="status">신청완료</mo-badge>
                            <mo-badge  class="badge-sample-badge blue sm"  v-if="fn_VstDtlYn(item.custSrvcRsltCd) ==='방문'" text="방문" shape="status">방문</mo-badge> 
                            <mo-badge  class="badge-sample-badge blue sm"  v-if="fn_VstDtlYn(item.custSrvcRsltCd) ==='미방문'" text="미방문" shape="status">미방문</mo-badge> 
                          </span>
                        </div>
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <span class="crTy-bk1">{{$cmUtil.frmtDateStr(item.custBirthYmd, 'yyyy-mm-dd')}}</span><em>|</em>
                            <span class="crTy-bk1">{{item.telno}}</span>
                          </div>
                          <span class="crTy-blue3 fwb" v-if="item.custSrvcTypCd === '01'" style="color:blue">{{fn_GetCustSrvcTypCd(item.custSrvcTypCd)}}</span>
                          <span class="crTy-blue3 fwb" v-if="item.custSrvcTypCd !== '01'">{{fn_GetCustSrvcTypCd(item.custSrvcTypCd)}}</span>
                        </div>
                     
                        <div class="list-item__inner" v-if="fn_GetYn(item.custSrvcAplYmd) === 'Y'" >
                          <div class="list-item__contents__info">
                            <span class="min70 fs14rem crTy-bk7 mr10 valignT">신청일자</span><span class="crTy-bk1">{{$cmUtil.frmtDateStr(item.custSrvcAplYmd, 'yyyy-mm-dd')}}</span>
                          </div>
                          <div class="list-item__contents__info">
                            <span class="min70 fs14rem crTy-bk7 mr10 valignT">신청물품</span><span class="crTy-bk1">{{item.gftCntnt}}</span>
                          </div>
                          <div class="list-item__contents__info">
                            <span class="min70 fs14rem crTy-bk7 mr10 valignT">결과</span><span class="crTy-bk1">{{fn_GetVstDtlYn(item.custSrvcProfrYmd, item.custSrvcRsltCd)}}</span>
                          </div>
                          <div class="list-item__contents__info">
                            <span class="min70 fs14rem crTy-bk7 mr10 valignT">신청경로</span><span class="crTy-bk1">{{item.srvcInflPathName}}</span>
                          </div>
                        </div>
                      </div>
                    </mo-list-item> 
                  </template>
                </mo-list>  
                
              </ur-box-container>
              </div> 

              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-list ns-check-list-detail"><!-- ns-list-area -->      
                  <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
                  <div :class="CMCustSrvcObjInfoVOList.length > 0 ? 'ns-height170' : ''"></div>
             

                <!-- 상단고정 Tab UI //-->
                <div :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp pre overflow-h' : 'ns-bottom-sheet ns-style3 only-btn pre overflow-h'">
                  <mo-bottom-sheet ref="nsbottomsheet2" noHeader>
                    <template v-slot:action>
                      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative" ><!-- ref="nsbottomsheet2"-->
                       <div class="relative-div">
                          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="selectItems.length > 0 && fn_Validate306(selectItems[0]) === 'pass' && hasUpdate === false"
                            @click="fn_OpenMSPCM306P">
                            서비스 신청
                          </mo-button>
                          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="selectItems.length > 0 && fn_Validate306(selectItems[0]) === 'pass' && hasUpdate === true"
                            @click="fn_OpenMSPCM306P" :disabled="selectItems[0].custSrvcAplYmd < lv_Today">
                            서비스 수정
                          </mo-button>
                          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="selectItems.length > 0 && fn_Validate306(selectItems[0]) === 'alrRequested'"
                            @click="fn_OpenMSPCM307P_BS">
                            결과입력
                          </mo-button>
                        </div>
                      </ur-box-container>
                    </template>
                  </mo-bottom-sheet>
                </div>
          
                <!-- 개인정보 이용 및 활용 동의 팝업 -->
                <mo-bottom-sheet ref="bottomSheet_CustInfo" class="ns-bottom-sheet ns-style3">
                  <template v-slot:title>고객카드 생성</template>
                  <div class="customer-info-txt">
                    <!-- <strong class="name">{{ $bizUtil.omitStr(pObjmsg.custNm, 12) }}</strong> -->
                    <strong class="name">{{ pObjmsg.custNm }}</strong>
                    <p class="info">생년월일 <span class="birth">{{ $commonUtil.dateDashFormat(pObjmsg.birthDay) }}</span><em>|</em><span>{{ pObjmsg.gender }}</span></p>
                    <p class="ment">
                      등록된 고객이 아닙니다.<br>
                      고객카드 자동 생성을 클릭하면, 해당 고객은 보험상담 동의처리 없이 고객카드 생성이 바로 됩니다.
                    </p>
                  </div>
                  <template v-slot:action>
                    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                      <div class="relative-div">
                        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Btn_C_OnClick()">취소</mo-button>
                        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Btn_I_OnClick()">고객카드 생성</mo-button>
                      </div>
                    </ur-box-container>
                  </template>
                </mo-bottom-sheet>
              </ur-box-container>

              <!-- <mo-index-scroll type="ko" class="ns-index-scroll" style="height:75vh;" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="clickInitial"></mo-index-scroll> -->
              <mo-index-scroll v-if="CMCustSrvcObjInfoVOList.length > 0 && selectedCustType === '27' && !applyEnd" :style="indexStyle" class="ns-index-scroll" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="clickInitial"></mo-index-scroll>

              <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="no-data" v-if="CMCustSrvcObjInfoVOList.length === 0 && isAfterFirstSearch === true">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span>데이터가 없습니다.</span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>

            </ur-box-container>
            </template> 
           
          </mo-collapsing-header-view> 
        </div>
      
    </ur-box-container>
   
    <mo-bottom-sheet ref="bottomSheet3" :close-btn="true" class="ns-bottom-sheet">
        <template v-slot:title>
          프리미엄고객사랑서비스 신청안내
          <div class="ns-btn-close" @click="fn_OnClose" name="닫기"></div>
        </template>
        <ul class="msp-pls-guide">
            <li class="msp-pls-txt fs16rem">
              <span>{{selectedDate.substr(4,2)}}월 선물 신청기간</span>
                <div style="width:100%; height:100%" v-show="pssBannerData.from.length > 4">
                   <b>{{pssBannerData.from}} ~ {{pssBannerData.to}}</b>
                </div>
                <div style="width:100%; height:100%" v-show="pssBannerData.from.length <= 4">
                   <b>선물신청 기간이 아닙니다.</b>
                </div>
            </li>
            <li class="msp-pls-txt fs16rem">
              <span>방문결과 입력기간</span><b>{{pssBannerData.vstTo}}까지</b>
            </li>
        </ul>
      <template v-slot:action>
        <ur-box-container alignV="start" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBs1" >확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 초회 진입 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isDataLoading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingTitle}}</span>
        <span class="txt" v-html="loadingMsg" />
      </div>
    </div>
    
  </ur-page-container>
</template>

    
<script>

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import MSPCM116P from '@/ui/cm/MSPCM116P' // 고객명 검색
  import MSPCM120P from '@/ui/cm/MSPCM120P' // 메모
  import MSPCM128P from '@/ui/cm/MSPCM128P' // 문자
  import MSPCM305P from '@/ui/cm/MSPCM305P' // 급여공제 동의
  import MSPCM306P from '@/ui/cm/MSPCM306P' // 프사서 서비스 신청
  import MSPCM307P from '@/ui/cm/MSPCM307P' // 프사서 결과입력
  import MSPAP302D from '@/ui/ap/MSPAP302D' // 일정
  import MSPCM307P_BS from '@/ui/cm/MSPCM307P_BS' // 프사서 방문결과부실입력금지
  import MspBottomSelect from '@/ui/sa/MSPSA203P_BottomSelect.vue'; // bottomsheet
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import DateUtil from '@/ui/sp/common/exDateUtils'
  import Screen from '~systems/mixin/screen'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM303M",
    screenId: "MSPCM303M",
    mixins: [Screen],
    props: {

    },
    components: {
      MspBottomSelect,
      MSPCM116P,
      MSPCM305P,
      MSPCM306P,
      MSPCM307P,
      MSPCM307P_BS,
      MSPCM128P,
      MSPAP302D,
      MSPCM120P
      },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        reqCnt:'',
        nReqCnt:'',
        cnt:0,
        applyEnd: false,
        attTip1: false,
        isCreat: true,
        isAfterFirstSearch: false,
        positionUp: false,
        isMngr: false, // 지점장 여부 /
        authCd: 'U000', // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        popup116: {}, // MSPCM116P 전체고객 목록 검색
        popup305: {}, // MSPCM305P 급여공제 동의
        popup116: {},
        popup305: {},
        popup306: {},
        popup307: {},
        popup128: {},
        popup302: {},
        popup120: {},
        // 컨설턴트정보(로그인)
        cnsltInfo: '',
        // 컨설턴트정보(변경가능)
        currentCnsltInfo: {
          userId: '',
          sarySbtrCnsntYn: '',
          onpstFofOrgNo: '',
          blckTypNm: '',
          onpstDofNo: ''
        },
        // 스크롤
        lv_HeaderviewScrollCompID: '',
        scrollElementId: '',
        // 고객카드 유무
        isCustCardExist: false,  
        // 체크 카운트
        checkedCnt: 0,
        // 전체 체크
        isOneOrMoreCheck: true,
        // 1개월 전
        unVstCustFgrYm: '',
        // 조회월
        searchDate: [],
        // 선택한 월
        selectedDate: '',
        //전월
        lastMonth:'',
        // 선택한 고객타입
        selectedCustType: '',
        // 배너등록 컨텐츠 수
        bannerTotCnt: 2,
        // 미방문 고객 수
        unVstCustFgr: 0,
        // 방문입력기간(시작)
        vstLmtStartDtm: '', 
        // 방문입력기간(종료)
        vstLmtEndDtm: '', 
        // 검색팝업의 고객명
        searchKeyword: '',
        // 검색팝업의 조직
        pOrgData303M: {},
        // 급여공제 데이터
        iCCustSrvcMngSbtrAmtVO:[],
        // 기신청고객 여부
        alrRequested: false,
        // 오늘신청 건 수정가능
        hasUpdate: false,
        // 배너 스와이프 옵션
        swiperOption: {
          centerSlides: true,
          slidesPerView: 1,
          spaceBetween: 10,
          autoplay: {
            delay: 20000,
            disableOnInteraction: false, 
          }
        },
        //버튼 포커스 제어
        isButtonSlct: false,
        // index-scroll
        indexStyle: {
          top:'350px',
          height:'calc(100vh - 460px)'
        },
        // 방문시작
        vstFrom: '',
        // 방문종료
        vstTo: '',
        // 기본월
        defaultMonth: '',
        // 코멘트
        sarySbtrMsgCntnt: '',
        // 디버그
        DEBUG: false,
        salaryDeduction: false,

        custSrvcAplYmd:'',
        // 서비스신청가능시작일자
        srvcAplStrYmd: '',
        // 서비스신청가능종료일자
        srvcAplEndYmd: '',
        // 결과입력시작일자
        vstInptStrYmd: '',
        // 결과입력종료일자
        vstInptEndYmd: '',
        // 조회된 달의 결과입력시작일자
        vstInptStrYmdNow: '',
        // 조회된 달의 결과입력종료일자
        vstInptEndYmdNow: '',
        // 더보기 키
        stndKeyList: [],
        // row count
        pageRowCnt: '20',
        // 더보기
        stndKeyVal: 'start',
        // 오늘
        todayYmd: '',
        isSuccessService: true,
        // 배너 데이터
        pssBannerData: {
          unVstCustFgr: 0,
          srvcAplName1: '',
          srvcAplName2: '',
          from: '',
          to: '',
          vstTo: '',
          bannerTotCnt: 2,
          cnsltUserId: '',
          objYm: '',
          vstLmtStartDtm: '', 
          vstLmtEndDtm: '',
          unVstCustFgrYm: '',
          serviceMonth: '',
          vstInptEndYmdNow: ''
        },
        // 고객카드 생성 변수
        pObjmsg: { // 고객카드 생성 Obj
          cnsltNo: '', // 컨설턴트번호(ZA_CHNL_ID)
          custId: '', // 고객ID(PARTNER)
          custNm: '', // 고객명
          knb: '', // 주민번호
          birthDay: '', // 생년월일
          gender: '' // 성별
        },
        // 월
        month: '',
        // 선택한 고객리스트
        selectedCustInfo: [],
        // 고객유형리스트
        filterSelectItemList: [],
        // 선택한 고객유형리스트
        filterSelectValue: '',
        // 신청경로
        sInflPathNm: [],
        selectItems: [],
        // 실제 화면 노출될 고객목록
        CMCustSrvcObjInfoVOList: [],
        // 미신청 고객 목록
        noApplyCustList: [],
        // 신청완료 고객 목록
        applyCustList: [],
        commCdList: {
          CUST_SRVC_TYP_CD: [],
          GFT_NO_CD: [],
          CUST_SRVC_RSLT_CD: []
        },
        pSelectParam: {},
        selectCheck: false,
        chkTm: '',
        isServicePoss: true,
        // 팝업 변수
        lv_inputParamS: {}, // 조회 Obj
        // 1. 전화
        iCCdCustMktPsbInfoVO: { // 고객카드의 동의(BP정보)
          mktCnvsMthTelYn: ''
        },
        cmCustCardInfoSVO: { // 고객 상세정보 인적사항
          chnlCustId: ' ',
          custId: ' ',
          telno: ''
        },
        // 2.문자
        custListObj: [], // 문자수신 고객 list
        // 4.메모
        member: { // 선택한 고객 정보(메모 팝업에 사용)
          custPtclrMatrCntnt: '', // 고객특이사항
          chnlCustId: '' // 채널고객 ID
        },
        lv_Today: '',
        isDateChgYn : false,
        isFirstEnter: true,
        isInitialSearch: false,
        isInitialCnt: 0,
        loadingTitle: '고객 정보 조회중',
        loadingMsg: '2~3분 정도 소요될 수 있습니다.',
        isDataLoading: false,
        isTypeCngYn: false
      };
    },


    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
     
    /** computed 함수 정의 영역 */
    computed: {
      topButtonBottom () {
        // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
        if (this.positionUp) return 150
        else return 0
      },
      disableMonth () {
        return this.$bizUtil.dateDashFormat(moment(new Date()).format('YYYYMMDD'))
      }
    },
    /** watch 정의 영역 */
    watch: {
      applyEnd () {
       // this.fn_SelListSrvcObjCust(nV)
        this.selectItems = []
        this.checkedCnt = 0
        this.isOneOrMoreCheck = false
        this.stndKeyVal = 'start'
        if (!this.isTypeCngYn) {
          // this.fn_SelListSrvcObjCust()
          this.fn_SelListSrvcObjBlckCustTyp()
        }
      }
    },
    /** 설명 : Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 */
    beforeCreate () {},
    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      console.log('MSPCM303M>>>> created...')
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      this.lv_Today = moment(new Date()).format('YYYYMMDD') // 오늘 날짜
      this.fn_ServicePoss()
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM303M')
      console.log('MSPCM303M>>>> mounted...')
      this.$refs.bottomSheet3.open();
      this.$nextTick(() => {
        // 스크롤 애니메이션 instance 선언
        // pk.son 20220728 퍼블수정
        // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
        //document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
        this.scrollElementId = this.$refs.collapsingHeaderView.getScrollElementId();

        const vm = this;
        document.getElementById(this.scrollElementId).addEventListener('scroll', (e) =>{
          const scrollTop = e.target.scrollTop;
          // const originalHeight = this.indexStyle.height;
          this.indexStyle = {
            top: Math.max(350-scrollTop, 170 ) + 'px',
            height: `calc(100vh - ${Math.max(460 - scrollTop, 280)}px)`
          }
        })
        // 공통코드 조회
        this.fn_CommCdList()
        // page(tab) view count
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM303M

        // 프사서 메인을 들어온 상태에서 다른 화면을 갔다온 경우 배제
        if(pageCnt === 0){
          // 최초접근
          window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM303M', -1)
        }else{
          // 프사서 활동현황에서 넘어온 경우를 제외하곤 카운트 쌓지 않음
          if (pageCnt % 2 === -0) {
            window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM303M', pageCnt - 1)
          }
        }
        this.$BottomManager.fn_SetBottomVisible(true)
      })

      this.$nextTick(() => {
        // 사용자 정보 조회
        this.fn_GetUserInfo()
      })
      // this.$nextTick(() => {
      //   // post 호출 전 Progress 타입 설정.
      //   window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
      //   // 조회시작
      //   this.fn_InitSearch()
      // })

    },

    activated () {
      this.lv_Today = moment(new Date()).format('YYYYMMDD') // 오늘 날짜
      window.vue.getStore('progress').dispatch('NONE')
      this.fn_ServicePoss()
      // this.fn_Btn_S_OnClick()
      this.$nextTick(() => {
        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')
        // 조회시작
        this.fn_InitSearch()
      })

      // 화면 스크롤을 위한 코드
      let sp = this.scrollPosition || 0
      if(sp > 10) {
        sp = sp - Math.ceil(sp*0.19)
      }
      this.$refs.page.scrollTo(sp, false)
      
      // let lv_vm = this
      // // 서버타임
      // lv_vm.$commonUtil.getServerTime().then( function (response) {
      //   lv_vm.chkTm = moment(response).format('HH')
      // })
      // // 조직/설계사 정보
      // this.cnsltInfo = this.getStore('userInfo').getters.getUserInfo
      // this.currentCnsltInfo.userId = this.cnsltInfo.userId
      // this.currentCnsltInfo.onpstDofNo = this.cnsltInfo.onpstDofNo
      

      // this.fn_Btn_E_Init_OnClick()
    },

    /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      this.$BottomManager.fn_SetBottomVisible(true)
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      closeTip(tip) {
        this.attTip1 = tip
        },
      fn_OnClickListItem(index) {
        if (this.currentCnsltInfo.userId !== this.cnsltInfo.userId) return
        this.$refs[`chk${index}`].onClick()
      },

      fn_MoveTabMSPCM310D() {
        console.log('활동현황 탭 클릭>>>', this.isSuccessService)
        if(this.isSuccessService === true){
          let obj = {cnsltUserId : this.currentCnsltInfo.userId}
          this.$router.push({name: 'MSPCM310D', params: obj})
        }
      
      },

      /******************************************************************************
       * Function명 : fn_myCustAdd
       * 설명       : 내고객 추가
       ******************************************************************************/
      fn_myCustAdd() {
        console.log("내고객추가버튼클릭")
        this.isButtonSlct = true
        this.isTypeCngYn = true
        this.selectItems = []
        this.checkedCnt = 0
        this.isOneOrMoreCheck = false
        this.$refs.nsbottomsheet2.close()
        this.selectedCustType ="27"
        this.stndKeyVal="start"
        this.applyEnd = false
        // lv_vm.fn_SelListSrvcObjCust(lv_vm.selectedCustType,lv_vm.stndKeyVal)
        this.fn_SelListSrvcObjBlckCustTyp()
      },
      /******************************************************************************
       * Function명 : fn_OnClose
       * 설명       : 팝업 닫음
       ******************************************************************************/
      fn_OnClose() {
        this.$emit('onPopupClose');
      },
        
      /*******************************************************
      * function명  : fn_GetUserInfo
      * 설명        : 사용자 조회
      * param       : cdEngNmList(CUST_SRVC_TYP_CD, GFT_NO_CD, CUST_SRVC_RSLT_CD)
      * Return      : N/A
      ******************************************************/
      fn_GetUserInfo: function () {
        // 로그인 유저 정보
        this.cnsltInfo = this.getStore('userInfo').getters.getUserInfo
        this.currentCnsltInfo.userId = this.cnsltInfo.userId
        if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
          // 지점장여부
          this.isMngr = true
        }
        
        // 조회조건 기본세팅
        // this.filterSelectItemList = this.$cmUtil.getCustType()
        let lv_vm = this
        lv_vm.filterSelectItemList = []
        window.vue.getInstance('vue').$commonUtil.selListMassSliCommCd(['CUST_SRVC_TYP_CD', 'CUST_SRVC_RSLT_CD', 'GFT_NO_CD'],)
          .then(function () {
            // 2-1. 고객유형 공통코드 데이터 형식 변경
            lv_vm.commCdList.CUST_SRVC_TYP_CD = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD')
            lv_vm.commCdList.CUST_SRVC_RSLT_CD = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_RSLT_CD')
            lv_vm.commCdList.GFT_NO_CD = lv_vm.getStore('code').getters.getState.get('GFT_NO_CD')

            lv_vm.filterSelectItemList.push({key: '1', value:'', label: '회사제공'})
            for (let i in lv_vm.commCdList.CUST_SRVC_TYP_CD) {
               if(lv_vm.commCdList.CUST_SRVC_TYP_CD[i]==='27'){
                  console.log("2222222222"+lv_vm.commCdList.CUST_SRVC_TYP_CD[i]);
                  lv_vm.filterSelectItemList.splice(i,1)
                }
              lv_vm.filterSelectItemList.push({
                key: lv_vm.commCdList.CUST_SRVC_TYP_CD[i].key,
                value: lv_vm.commCdList.CUST_SRVC_TYP_CD[i].key,
                label: lv_vm.commCdList.CUST_SRVC_TYP_CD[i].label
              })
            }
            console.log('selectBox 목록>>', lv_vm.filterSelectItemList)
            lv_vm.filterSelectValue = lv_vm.filterSelectItemList[0].value
          })      
        this.sInflPathNm = this.$cmUtil.getSInflPathNm()

     

        this.searchDate.push(new Date().getFullYear().toString())
        let tempMonth = (new Date().getMonth() + 1).toString()
        if(tempMonth.length === 1){
          tempMonth = '0' + tempMonth
        }
        this.searchDate.push(tempMonth)
        this.selectedDate = this.searchDate[0]+this.searchDate[1]
        this.lastMonth = this.selectedDate -1
        
        this.unVstCustFgrYm = DateUtil.fn_AddMonth(this.lv_Today, -1).substring(0, 6);
        this.pssBannerData.unVstCustFgrYm = this.unVstCustFgrYm
        
        this.currentCnsltInfo.userId = this.cnsltInfo.userId
        this.currentCnsltInfo.onpstFofOrgNo = this.cnsltInfo.onpstFofOrgNo
        this.currentCnsltInfo.onpstDofNo = this.cnsltInfo.onpstDofNo
      },

      /*******************************************************
      * function명  : fn_IinitSearch
      * 설명        : 초기 조건 조회
      * param       : N/A
      * Return      : Array
      ******************************************************/
      fn_InitSearch: async function () {
        let lv_vm = this
        // 초기 날짜 설정
        await lv_vm.fn_GetInitDate()
        // 조직정보 조회
        await lv_vm.fn_OrgLvlSlct()
       
        // this.$refs.bottomSheet3.open();
        // let pChk = document.getElementById("pCnt")
        // pChk.style.display='none';
        // let nReqCnt = document.getElementById("nReqCnt")
        // nReqCnt.style.display='none';
        
        
      },
      /*******************************************************
        * function명  : fn_CloseBs1
        * 설명        : sheet close
        ******************************************************/      
        fn_CloseBs1() {
          this.$refs.bottomSheet3.close()
        },

      /*******************************************************
      * function명  : fn_GetInitDate
      * 설명        : 초기 날짜 설정
      * param       : N/A
      * Return      : Array
      ******************************************************/
      fn_GetInitDate: function () {
        this.month = this.lv_Today.substring(0, 6)
      },

      /*******************************************************
      * function명  : fn_CommCdList 
      * 설명        : 공통코드 조회(IF-BC-0020, txTSSBC13S7) Service1
      * param       : cdEngNmList(CUST_SRVC_TYP_CD, GFT_NO_CD, CUST_SRVC_RSLT_CD)
      * Return      : N/A
      ******************************************************/
      fn_CommCdList: function () {
        let lv_vm = this
        // lv_vm.filterSelectItemList = []
        window.vue.getInstance('vue').$commonUtil.selListMassSliCommCd(['CUST_SRVC_TYP_CD', 'CUST_SRVC_RSLT_CD', 'GFT_NO_CD'],)
          .then(function () {
            // 2-1. 고객유형 공통코드 데이터 형식 변경
            lv_vm.commCdList.CUST_SRVC_TYP_CD = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD')
            lv_vm.commCdList.CUST_SRVC_RSLT_CD = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_RSLT_CD')
            lv_vm.commCdList.GFT_NO_CD = lv_vm.getStore('code').getters.getState.get('GFT_NO_CD')
            
            /*
            lv_vm.filterSelectItemList.push({key: '1', value:'', label: '전체'})
            for (let i in lv_vm.commCdList.CUST_SRVC_TYP_CD) {
              lv_vm.filterSelectItemList.push({
                key: lv_vm.commCdList.CUST_SRVC_TYP_CD[i].key,
                value: lv_vm.commCdList.CUST_SRVC_TYP_CD[i].key,
                label: lv_vm.commCdList.CUST_SRVC_TYP_CD[i].label
              })
            }
            */
          })
      },

      /*******************************************************
      * function명  : fn_OrgLvlSlct
      * 설명        : 조직정보 조회
      * param       : N/A
      * Return      : Array
      ******************************************************/
      fn_OrgLvlSlct: function () {
        let lv_vm = this
        lv_vm.cnsltInfo = lv_vm.getStore('userInfo').getters.getUserInfo
        
        if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
          lv_vm.isMngr = true
        }
        lv_vm.fn_SelListSrvcObjBlckCustTyp()
      },

      /*******************************************************
      * function명  : fn_SelListSrvcObjBlckCustTyp
      * 설명        : 컨설턴트 블로킹 여부 조회 Service2
      *               IF-CM-3005 txTSSCM51S0
      * param       : orgNo, cnsltNo, objYm
      * Return      : blckTypNm
      ******************************************************/
      fn_SelListSrvcObjBlckCustTyp () {
        let lv_vm = this
        let trnstId = 'txTSSCM51S0'
        lv_vm.selectCheck = true
        lv_vm.isDataLoading = true
        let pParams = {
          objYm: lv_vm.selectedDate,
          orgNo: lv_vm.currentCnsltInfo.onpstFofOrgNo,
          cnsltNo: lv_vm.currentCnsltInfo.userId
        }
        lv_vm.post(lv_vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU001') {
              let res = response.body
              let blockType = (res.blckTypNm.length === 0) ? 'N' : res.blckTypNm
              lv_vm.currentCnsltInfo.blckTypNm = blockType
              lv_vm.stndKeyList = response.trnstComm.stndKeyList
              lv_vm.fn_Btn_S_OnClick()
            } else {
              setTimeout(function () {
                lv_vm.isInitialCnt = 0
              },500)
              lv_vm.isDataLoading = false
              lv_vm.isTypeCngYn = false
              lv_vm.$addSnackbar('[FC블로킹여부조회] 오류가 발생했습니다. 다시 시도해주십시오')
            }

          })
      },

      /*******************************************************
      * function명  : fn_Btn_S_OnClick
      * 설명        : 조회버튼 클릭 이벤트
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_Btn_S_OnClick: function () {
        console.log('열린화면 재조회 테스트>>>')
        this.stndKeyVal = 'start'
        this.stndKeyList = []
        //this.selectItems.splice(0, this.selectItems.length)
        //this.CMCustSrvcObjInfoVOList.splice(0, this.CMCustSrvcObjInfoVOList.length)
        this.defaultMonth = this.month
        // 공제여부조회
        this.fn_IsSarySbtrCnsntYn()
        // 체크된 고객 초기화
        let selectItems_init = []
        this.$cmUtil.setSelectedCustList(this, selectItems_init)
        // this.$refs.bottomSheet3.open();
       
      },

      /*******************************************************
      * function명  : fn_IsSarySbtrCnsntYn
      * 설명        : 프리미엄고객사랑서비스 공제여부조회
      *               IF-CM-3017 txTSSCM51S4
      * param       : cnsltNo, objYm
      * Return      : cnsltNo, objYm, sarySbtrCnsntYn
      ******************************************************/
      fn_IsSarySbtrCnsntYn () {
        let lv_vm = this
        let trnstId = 'txTSSCM51S4'
        let isCurrentCnsltMngr = lv_vm.currentCnsltInfo.userId.startsWith('10')
        if (isCurrentCnsltMngr) {
        // if (lv_vm.currentCnsltInfo.sarySbtrCnsntYn === 'Y' || isCurrentCnsltMngr) {
          //lv_vm.currentCnsltInfo.sarySbtrCnsntYn 'Y':'공제동의'
          //isCurrentCnsltMngr true :  '임직원계정'
          lv_vm.currentCnsltInfo.sarySbtrCnsntYn = 'Y'
          // lv_vm.isDataLoading = false
          lv_vm.fn_SelListSrvcUnVstCustFgr()
          return
        }

        let pParams = {
          cnsltNo: lv_vm.currentCnsltInfo.userId,
          objYm: lv_vm.selectedDate,
          custSrvcScCd: '1' // 프사서 : 1 , 스사서 : 2
        }

        lv_vm.post(lv_vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU001') {
              let data = response.body
              // lv_vm.isDataLoading = false
              if (data.sarySbtrCnsntYn === 'Y') {
                lv_vm.currentCnsltInfo.sarySbtrCnsntYn = 'Y'
                lv_vm.fn_SelListSrvcUnVstCustFgr()
              } else {
                setTimeout(function () {
                  lv_vm.isInitialCnt = 0
                },500)
                lv_vm.isDataLoading = false
                lv_vm.isTypeCngYn = false
                if (lv_vm.currentCnsltInfo.userId !== lv_vm.cnsltInfo.userId) {
                  lv_vm.$addSnackbar('급여공제가 되지 않은 FC 입니다')
                  return
                }
                // 급여동의 메시지 조회
                lv_vm.fn_SelListCustSrvcMngSarySbtrInfo()
                lv_vm.salaryDeduction = true
              }
            } else {
              setTimeout(function () {
                lv_vm.isInitialCnt = 0
              },500)
              lv_vm.isDataLoading = false
              lv_vm.isTypeCngYn = false
              lv_vm.$addSnackbar('[FC 공제여부 조회] 오류가 발생했습니다. 다시 시도해주십시오')
            }
          })
      },

      /*******************************************************
      * function명  : fn_LoadingData
      * 설명        : 프리미엄고객사랑서비스 대상 조회(loading시)
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_LoadingData () {
        this.selectCheck = false

        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')
        
        // 대상 조회
        this.fn_SelListSrvcObjCust()
      },

      /*******************************************************
      * function명  : fn_SelListSrvcUnVstCustFgr
      * 설명        : 프리미엄고객사랑서비스 대상 조회(방문결과 미입력 1개월전)
      *               IF-CM-3004 txTSSCM51S1
      * param       : cnsltNo, objYm, custSrvcTypCd, selfCnfYn, blckTypNm, custNm(추가예정)
      * Return      : OBJECT
      ******************************************************/
      fn_SelListSrvcUnVstCustFgr: function () {
        let lv_vm = this
        if (lv_vm.selectCheck) {
          if (lv_vm.currentCnsltInfo.sarySbtrCnsntYn === 'N' || lv_vm.currentCnsltInfo.sarySbtrCnsntYn === '') {
            lv_vm.salaryDeduction = true
            setTimeout(function () {
              lv_vm.isInitialCnt = 0
            },500)
            lv_vm.isDataLoading = false
            return
          }
        }
        console.log('lv_vm.currentCnsltInfo.blckTypNm>>>', lv_vm.currentCnsltInfo.blckTypNm)
        lv_vm.$bizUtil.ctUtils.fn_PageContainerScrollTop(lv_vm.$refs.page)
        // 조회 파라미터 복사
        lv_vm.pSelectParam = {
          custSrvcAplYmd:lv_vm.custSrvcAplYmd,
          cnsltNo: lv_vm.currentCnsltInfo.userId,
          objYm: lv_vm.unVstCustFgrYm,
          custSrvcTypCd: '',
          blckTypNm: (lv_vm.currentCnsltInfo.blckTypNm === 'N') ? '' : lv_vm.currentCnsltInfo.blckTypNm,
          custNm: '',
          custSrvcScCd: (lv_vm.selectedCustType === '02') ? '3' : '1'// 1-프사서, 2-스사서
        }
        // lv_vm.isDataLoading = true
        console.log('방문결과 미입력 1개월전 조회 파라미터 비교', lv_vm.pSelectParam)
        // let trnstId = 'txTSSCM51S1'
        let trnstId = 'txTSSCM48S1'
        lv_vm.post(lv_vm, lv_vm.pSelectParam, trnstId, 'S')
          .then(function (response) {
            lv_vm.$commonUtil.getServerTime().then( function (response) {
              lv_vm.todayYmd = moment(response).format('YYYYMMDD')
            })
            lv_vm.stndKeyList = []
            // lv_vm.isDataLoading = false
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU001') {
              // 미방문 고객 수
              lv_vm.unVstCustFgr = response.body.unVstCustFgr ? Number(response.body.unVstCustFgr) : 0
              lv_vm.pssBannerData.unVstCustFgr = response.body.unVstCustFgr ? Number(response.body.unVstCustFgr) : 0
              console.log('미방문 고객수!!!!', response.body.unVstCustFgr , lv_vm.unVstCustFgr , lv_vm.pssBannerData.unVstCustFgr)
              // 결과입력시작일자
              lv_vm.vstInptStrYmd = response.body.vstInptStrYmd
              // 결과입력종료일자
              lv_vm.vstInptEndYmd = response.body.vstInptEndYmd
              console.log('결과입력 종료일자', response.body.vstInptEndYmd)
              lv_vm.fn_SelListSrvcObjCust()
            } else {
              lv_vm.$refs.nsbottomsheet2.close()
              setTimeout(function () {
                lv_vm.isInitialCnt = 0  
              },500)
              lv_vm.isDataLoading = false
              lv_vm.isTypeCngYn = false
              lv_vm.$addSnackbar('[고객조회] 오류가 발생했습니다. 다시 시도해주십시오')//
            }
          })
      },

      /*******************************************************
      * function명  : fn_SelListSrvcObjCust
      * 설명        : 프리미엄고객사랑서비스 대상 조회
      *               IF-CM-3004 txTSSCM51S1
      * param       : cnsltNo, objYm, custSrvcTypCd, selfCnfYn, blckTypNm, custNm,custSrvcAplYmd(추가예정)
      * Return      : OBJECT
      ******************************************************/
      fn_SelListSrvcObjCust: function () {
        let lv_vm = this
        window.vue.getStore('progress').dispatch('NONE')
        lv_vm.isDataLoading = true
        if (lv_vm.selectCheck) {
          if (lv_vm.currentCnsltInfo.sarySbtrCnsntYn === 'N' || lv_vm.currentCnsltInfo.sarySbtrCnsntYn === '') {
            lv_vm.salaryDeduction = true
            setTimeout(function () {
              lv_vm.isInitialCnt = 0
            },500)
            lv_vm.isDataLoading = false
            lv_vm.isTypeCngYn = false
            return
          }
        }
        if (lv_vm.stndKeyVal == 'start' || (lv_vm.selectedCustType === '27' && lv_vm.isInitialCnt > 1)) {
          console.log('초성클릭 중복호출!!!!')
          lv_vm.noApplyCustList.splice(0)
          lv_vm.applyCustList.splice(0)
        }
        console.log('무한로딩???', lv_vm.stndKeyVal)
        console.log('lv_vm.applyEnd???', lv_vm.applyEnd)
        if (lv_vm.stndKeyVal === 'end'){
          setTimeout(function () {
              lv_vm.isInitialCnt = 0
            },500)
          lv_vm.isDataLoading = false
          lv_vm.isTypeCngYn = false
          return
        } else{
          lv_vm.isOneOrMoreCheck = false
        }
        // 조회 파라미터 복사
        lv_vm.pSelectParam = {
          custSrvcAplYmd:lv_vm.custSrvcAplYmd,
          cnsltNo: lv_vm.currentCnsltInfo.userId,
          objYm: lv_vm.selectedDate,
          custSrvcTypCd: lv_vm.selectedCustType,
          blckTypNm: (lv_vm.currentCnsltInfo.blckTypNm === 'N') ? '' : lv_vm.currentCnsltInfo.blckTypNm,
          custNm: lv_vm.applyEnd ? '' : lv_vm.searchKeyword,
          custSrvcScCd: (lv_vm.selectedCustType === '02') ? '3' : '1'// 1-프사서, 2-스사서
          
        }
        if (lv_vm.applyEnd) {
          if (lv_vm.stndKeyVal === 'start') {
            if (lv_vm.stndKeyList.length > 0) {
              lv_vm.stndKeyList[0].stndKeyVal = ' '
            }
          }
        }
        console.log('조회 전 파라미터>>>', lv_vm.pSelectParam , lv_vm.stndKeyList)
        // let trnstId = 'txTSSCM51S1'
        let trnstId = 'txTSSCM48S1'
        lv_vm.post(lv_vm, lv_vm.pSelectParam, trnstId, 'S')
          .then(function (response) {
            console.log('조회 후!!!!!!!!', response)
            lv_vm.isTypeCngYn = false
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU001') {
                // 서비스신청가능시작일자
                lv_vm.srvcAplStrYmd = response.body.srvcAplStrYmd
                // 서비스신청가능종료일자
                lv_vm.srvcAplEndYmd = response.body.srvcAplEndYmd
                // 결과입력시작일자
                lv_vm.vstInptStrYmdNow = response.body.vstInptStrYmd
                // 결과입력종료일자
                lv_vm.vstInptEndYmdNow = response.body.vstInptEndYmd
  
                lv_vm.custSrvcAplYmd = response.body.custSrvcAplYmd
                let msg = {}
                msg.startMonth = response.body.srvcAplStrYmd.substring(4, 6)
                msg.startDay   = response.body.srvcAplStrYmd.substring(6, 8)
                msg.endMonth   = response.body.srvcAplEndYmd.substring(4, 6)
                msg.endDay     = response.body.srvcAplEndYmd.substring(6, 8)

                // 방문결과 입력기간
                msg.vstEndMonth   = response.body.vstInptEndYmd.substring(4, 6)
                msg.vstEndDay     = response.body.vstInptEndYmd.substring(6, 8)
                
                lv_vm.stndKeyList = response.trnstComm.stndKeyList
                let responseList = response.body.iCCustSrvcObjInfoVO
                if (responseList != null) {
                  let reqCnt=0
                  let nReqCnt=0
                  let cnt =0
                  let srvTypList = lv_vm.commCdList.CUST_SRVC_TYP_CD
                  let gftList = lv_vm.commCdList.GFT_NO_CD
                  for (let i = 0; i < responseList.length; i++) {
                    // 고객구분
                    if(srvTypList !== undefined && srvTypList !== null) {
                      for(let l = 0; l < srvTypList.length; l++){
                        //  if(srvTypList[l].cdVal ==='27'){
                        //     console.log("srvTypList[l].cdVal@@@@@@@@@@@@@@@ "+srvTypList[l].cdVal );
                        //     srvTypList.splice(l,1)
                        //     } 
                        //  console.log('custSrvcTypCdList = ' + srvTypList[l].cdVal + ' . ' + srvTypList[l].cdValNm)
                          if(srvTypList[l].cdVal === responseList[i].custSrvcTypCd){
                            responseList[i].custSrvcTypNm = srvTypList[l].cdValNm
                          }
                      }
                    }
                    // 신청물품
                    if(responseList[i].gftCntnt === '') {
                      if(gftList !== undefined && gftList !== null){
                        for(let k = 0; k < gftList.length; k++){
                          if(gftList[k].cdVal === responseList[i].gftNoCd){
                            responseList[i].gftCntnt = gftList[k].cdValNm
                          }
                        }
                      }
                    }
                    responseList[i].ck = false
                    responseList[i].checked = false //체크박스
                    responseList[i].srvcInflPathName = lv_vm.fn_GetInflPathNm(responseList[i].srvcInflPathCd.trim())//pk.son
                  }
                  if (lv_vm.stndKeyVal == 'start') {
                    lv_vm.noApplyCustList.splice(0)
                    lv_vm.applyCustList.splice(0)
                  } 
                  console.log('프사서 고객 목록>>', responseList)
                  for (let i = 0; i < responseList.length; i++) {
                    if(lv_vm.fn_GetYn(responseList[i].custSrvcAplYmd)==='Y' && lv_vm.applyEnd){ // 신청완료
                      lv_vm.applyCustList.push(responseList[i])
                    } 
                    lv_vm.noApplyCustList.push(responseList[i])
                  }
                  console.log('전체 목록>>', lv_vm.noApplyCustList)
                  console.log('신청완료 목록>>', lv_vm.applyCustList)
                  if (lv_vm.applyEnd) {
                    lv_vm.CMCustSrvcObjInfoVOList = lv_vm.applyCustList
                  } else {
                    lv_vm.CMCustSrvcObjInfoVOList = lv_vm.noApplyCustList
                  }
                  console.log('최종 목록>>', lv_vm.CMCustSrvcObjInfoVOList)
                  console.log('lv_vm.stndKeyList>>', lv_vm.stndKeyList)
                  if (lv_vm.stndKeyList.length > 0) {
                    console.log('lv_vm.stndKeyList111>>', lv_vm.stndKeyList[0].stndKeyVal.trim() === '')
                  }
                  lv_vm.isDataLoading = false
                  if (lv_vm.stndKeyList === null || lv_vm.stndKeyList.length === 0 || lv_vm.stndKeyList[0] === null || lv_vm.stndKeyList[0].stndKeyVal === null || lv_vm.stndKeyList[0].stndKeyVal.trim() === '') {
                    lv_vm.stndKeyVal = 'end'
                  } else {
                    lv_vm.stndKeyVal = 'more'
                    if (lv_vm.applyEnd) {
                        lv_vm.fn_SelListSrvcObjCust()
                        return
                    }
                  }
                  console.log('최종 lv_vm.stndKeyVal>>>>', lv_vm.stndKeyVal)
                } else { // response null
                  console.log('데이터 없음', response)
                  lv_vm.CMCustSrvcObjInfoVOList = []
                  lv_vm.noApplyCustList = []
                  lv_vm.applyCustList = []
                  lv_vm.stndKeyVal = 'end'
                  lv_vm.isDataLoading = false
                }

              if (lv_vm.isDateChgYn) {
                lv_vm.isDateChgYn = false
                lv_vm.$refs.bottomSheet3.open()
              }
              // if(lv_vm.isCreat === true){
                lv_vm.pssBannerData.serviceMonth = lv_vm.selectedDate.substr(4,2)
                lv_vm.pssBannerData.from = msg.startMonth + '월 ' + msg.startDay + '일 '
                lv_vm.pssBannerData.to = msg.endMonth + '월 ' + msg.endDay + '일'
                lv_vm.pssBannerData.vstTo = msg.vstEndMonth + '월 ' + msg.vstEndDay + '일'
                lv_vm.pssBannerData.srvcAplName1 = '*선물신청 기간: '
                lv_vm.pssBannerData.srvcAplName2 = '*방문결과 입력기간 : 익월  D+3일까지'
                lv_vm.pssBannerData.cnsltUserId = lv_vm.currentCnsltInfo.userId
                lv_vm.pssBannerData.objYm = lv_vm.selectedDate
                lv_vm.pssBannerData.vstLmtStartDtm = lv_vm.vstInptStrYmd.substring(4, 6) + '월 ' + lv_vm.vstInptStrYmd.substring(6, 8) + '일'
                lv_vm.pssBannerData.vstLmtEndDtm = lv_vm.vstInptEndYmd.substring(4, 6) + '월 ' + lv_vm.vstInptEndYmd.substring(6, 8) + '일'
                lv_vm.pssBannerData.vstInptEndYmdNow = lv_vm.vstInptEndYmdNow
                lv_vm.pssBannerData.vstInptEndYmd = lv_vm.vstInptEndYmd
                // lv_vm.pssBannerData.vstInptEndYmd = '20240515'
                console.log('배너 데이터 확인 vstInptEndYmd>>>>', lv_vm.pssBannerData)
                console.log('lv_vm.isFirstEnter>>>>', lv_vm.isFirstEnter)
                console.log('배너 노출 조건 인원>>>>', Number(lv_vm.pssBannerData.unVstCustFgr))
                console.log('배너 노출 조건 기간>>>>', Number(lv_vm.pssBannerData.vstInptEndYmd) - Number(lv_vm.lv_Today))
                window.vue.getStore('cmStore').dispatch('PSS_BANNER_DATA', lv_vm.pssBannerData)

              if (lv_vm.isFirstEnter) {
                let diff = Number(lv_vm.pssBannerData.vstInptEndYmd) - Number(lv_vm.lv_Today)
                if (Number(lv_vm.pssBannerData.unVstCustFgr) > 0 && ( diff<= 3 && diff >= 0 )) {
                  lv_vm.attTip1 = true
                } else {
                  lv_vm.attTip1 = false
                }
              }
              lv_vm.isFirstEnter = false
              // }
              // 오늘이 방문입력 종료일 하루 전일경우 배너갯수 다시 변경
              // if(lv_vm.isAfterFirstSearch === false){
              //   if(lv_vm.todayYmd !== '' && lv_vm.vstInptEndYmd !== ''){
              //     if(lv_vm.todayYmd === lv_vm.vstInptEndYmd ||
              //         lv_vm.todayYmd === lv_vm.$cmUtil.dateCal(lv_vm.$cmUtil.frmtDateStr(lv_vm.vstInptEndYmd, 'yyyy-mm-dd'),'day', -1) ||
              //         lv_vm.todayYmd === lv_vm.$cmUtil.dateCal(lv_vm.$cmUtil.frmtDateStr(lv_vm.vstInptEndYmd, 'yyyy-mm-dd'),'day', -2) ||
              //         lv_vm.todayYmd === lv_vm.$cmUtil.dateCal(lv_vm.$cmUtil.frmtDateStr(lv_vm.vstInptEndYmd, 'yyyy-mm-dd'),'day', -3)
              //     ){
              //       if(lv_vm.unVstCustFgr !== undefined && lv_vm.unVstCustFgr !== null){
              //         if (Number(lv_vm.unVstCustFgr) > 0) {
              //           lv_vm.pssBannerData.bannerTotCnt = 2
              //           lv_vm.bannerTotCnt = 2
              //         } else {
              //           lv_vm.pssBannerData.bannerTotCnt = 1
              //           lv_vm.bannerTotCnt = 1
              //         }
              //       }else{
              //         lv_vm.pssBannerData.bannerTotCnt = 1
              //         lv_vm.bannerTotCnt = 1
              //       }
              //     }else{
              //       lv_vm.pssBannerData.bannerTotCnt = 1
              //       lv_vm.bannerTotCnt = 1
              //     }
              //   }
              //   window.vue.getStore('cmStore').dispatch('PSS_BANNER_DATA', lv_vm.pssBannerData)
              // }
              lv_vm.isAfterFirstSearch = true
              lv_vm.isSuccessService = true
            } else {
              lv_vm.isSuccessService = false
              lv_vm.$refs.nsbottomsheet2.close()
              lv_vm.pssBannerData.bannerTotCnt = 1
              lv_vm.bannerTotCnt = 1
              lv_vm.$addSnackbar('[고객조회] 오류가 발생했습니다. 다시 시도해주십시오')//
            }
            // lv_vm.searchKeyword = ''
            if (lv_vm.isInitialSearch) {
              lv_vm.searchKeyword = ''
              lv_vm.isInitialSearch = false
              setTimeout(function () {
                lv_vm.isInitialCnt = 0
              },500)
            }

            // if(lv_vm.isCreat === true){
            //   // 배너 swiper 적용되지 않아 강제로 업데이트
            //   if (lv_vm.$refs !== undefined && lv_vm.$refs.swiper !== undefined) {
            //     lv_vm.$refs.swiper.$forceUpdate();
            //   }
            //   if (lv_vm.$refs !== undefined && lv_vm.$refs.swiper1 !== undefined) {
            //     lv_vm.$refs.swiper1.$forceUpdate();
            //   }
            //   // 배너 조정
            //   lv_vm.$refs.collapsingHeaderView.adjustHeight()
            // }
            lv_vm.isCreat = false
            lv_vm.isDataLoading = false
          })
      },


      /*******************************************************
      * function명  : fn_SelListCustSrvcMngSarySbtrInfo
      * 설명        : 급여공제 동의 메시지 조회
      *               txTSSCM51S5
      * param       : custSrvcScCd
      * Return      : OBJECT
      ******************************************************/
      fn_SelListCustSrvcMngSarySbtrInfo: function () {
        let lv_vm = this
        let pParams = {
          custSrvcScCd: '1' // 프사서 -1 , 스사서 -2
        }
        let trnstId = 'txTSSCM51S5'
        lv_vm.post(lv_vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU044') {
              lv_vm.sarySbtrMsgCntnt = lv_vm.fn_GetSarySbtrMsgCntnt(response.body.sarySbtrMsgCntnt)
              lv_vm.iCCustSrvcMngSbtrAmtVO = response.body.iCCustSrvcMngSbtrAmtVO
              // if (lv_vm.iCCustSrvcMngSbtrAmtVO !== null && lv_vm.iCCustSrvcMngSbtrAmtVO.length > 0) {
              //   lv_vm.sbtrAmt = ''
              //   for (let i = 0; i < lv_vm.iCCustSrvcMngSbtrAmtVO.length; i++) {
              //     lv_vm.sbtrAmt += lv_vm.iCCustSrvcMngSbtrAmtVO[i].custScVal + ' - ' + lv_vm.iCCustSrvcMngSbtrAmtVO[i].sarySbtrAmtVal + '원<br>'
              //   }
              // }
              lv_vm.fn_OpenMSPCM305P()
            } else {
              lv_vm.$addSnackbar('[공제메시지조회] 오류가 발생했습니다. 다시 시도해주십시오')
            }
          })
      },

      /*******************************************************
      * function명  : fn_InsSarySbtrCnsntYn
      * 설명        : FC 고객사랑서비스 공제여부반영
      *               IF-CM-3018 txTSSCM51I1
      * param       : cnsltNo, objYm, sarySbtrCnsntYn
      * Return      : cnsltNo, objYm
      ******************************************************/
      fn_InsSarySbtrCnsntYn () {
        let lv_vm = this
        let trnstId = 'txTSSCM51I1'

        let pParams = {
          cnsltNo: lv_vm.cnsltInfo.userId,
          objYm: lv_vm.selectedDate,
          custSrvcScCd: '1', // 프사서 -1 , 스사서 -2
          trtrEno: lv_vm.cnsltInfo.userId
        }
        if (pParams.cnsltNo === '' || pParams.cnsltNo === undefined || pParams.cnsltNo === null) {
          pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
        }
        this.post(lv_vm, pParams, trnstId, 'I')
          .then(function (response) {
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU002') {
              lv_vm.currentCnsltInfo.sarySbtrCnsntYn = 'Y'
              lv_vm.salaryDeduction = false
              lv_vm.fn_SelListSrvcUnVstCustFgr()
              //lv_vm.fn_SelListSrvcObjCust()
            } else {
              lv_vm.$addSnackbar('[공제여부반영] 오류가 발생했습니다. 다시 시도해주십시오')
            }
          })
      },

      /*******************************************************
      * function명  : fn_SelListMyCustAddInfo
      * 설명        : 고객사랑서비스 신청가능 여부 조회
      *               IF-CM-3048 txTSSCM51S3
      * param       : Object(objYm, cnsltNo, custSrvcAplNo, custSrvcTypCd)
      * Return      : Boolean
      ******************************************************/
      fn_SelListMyCustAddInfo: async function (param) {
        let lv_vm = this
        let trnstId = 'txTSSCM51S3'
        let flag = false
        lv_vm.custInfo = param
        let pParam = {
          objYm: lv_vm.selectedDate,
          cnsltNo: lv_vm.custInfo.cnsltNo,
          custSrvcAplNo: lv_vm.custInfo.custSrvcAplNo,
          custSrvcTypCd: lv_vm.custInfo.custSrvcTypCd
        }
        await lv_vm.post(lv_vm, pParam, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU044') {
              if (response.body.custAddmPsbYn === 'Y') {
                flag = true
              } else {
                flag = false
              }
            } else {
              lv_vm.$addSnackbar('[신청 가능 여부 조회] 오류가 발생했습니다. 다시 시도해주십시오')
            }
          })
        return flag
      },

      /*******************************************************
      * function명  : fn_UpdSrvcAplInfo
      * 설명        : 고객사랑서비스 신청 메서드
      *               IF-CM-3011 txTSSCM51U2
      * param       : Object
      * Return      : N/A
      ******************************************************/
      fn_UpdSrvcAplInfo: function (custInfo) {
        let lv_vm = this
        // 2. [IF-CM-3011] 고객사랑서비스 신청
        let pParams = {
          objYm: lv_vm.selectedDate,
          cnsltNo: custInfo.cnsltNo,
          custId: custInfo.custId,
          custSrvcAplNo: custInfo.custSrvcAplNo,
          gftNoCd: custInfo.gftNoCd,
          gftCntnt: custInfo.gftCntnt,
          custSrvcAplYn: 'Y',
          custSrvcAplYmd: custInfo.custSrvcAplYmd,
          srvcInflPathCd: '8', // 서비스유입경로코드 - "5":유파트너, "6":사랑온, "8":NEXT 사랑온
          custSrvcTypCd: custInfo.custSrvcTypCd
        }

        let trnstId = 'txTSSCM51U2'
        lv_vm.post(lv_vm, pParams, trnstId, 'U')
          .then(function (response) {
            if (lv_vm.DEBUG) console.log(response)
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECOU002') {
              lv_vm.$addSnackbar('정상적으로 신청되었습니다.')
              lv_vm.fn_Btn_S_OnClick()
            } else {
              lv_vm.$addSnackbar('[서비스 신청] 오류가 발생했습니다. 다시 시도해주십시오')
            }
          })
      },
      /*********************************************************
      * Function명: fn_OpenMSPCM116P
      * 설명: 전체고객 목록 검색 팝업 호출
      *********************************************************/
      fn_OpenMSPCM116P () {
        let lv_vm = this
        console.log('고객검색 데이터 확인>>', lv_vm.pOrgData303M , '/' , lv_vm.searchKeyword , '/', lv_vm.isMngr)
        this.popup116 = this.$bottomModal.open(MSPCM116P, {
          properties: {
            pPage: 'MSPCM303M',
            pOrgData: lv_vm.pOrgData303M, // 최근 조직 조회 데이터
            pSearchKeyword: lv_vm.searchKeyword, // 고객명
            pIsMngr: lv_vm.isMngr, // 지점장 여부
            // 공통 팝업을 사용하므로 prop 에러를 제거하기 위해 넣어주는 변수
            pOrgData115M: {},
            pOrgData124M: {},
          },
          listeners: {
            onPopupSearch: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup116)
              lv_vm.searchKeyword = pData.rtnData.searchKeyword
              // 마지막 조직 조회 데이터 셋팅
              lv_vm.pOrgData303M = pData.rtnData.pOrgData
              lv_vm.currentCnsltInfo.userId = pData.rtnData.cnsltEno
              lv_vm.currentCnsltInfo.onpstFofOrgNo = pData.rtnData.fofOrgCd
              lv_vm.currentCnsltInfo.onpstDofNo = pData.rtnData.dofOrgCd
              if (lv_vm.currentCnsltInfo.blckTypNm === 'N') {
                // 조회 시작
                lv_vm.fn_SelListSrvcObjBlckCustTyp()
              }
            }
          }
        })
      },

      /*********************************************************
      * Function명: fn_OpenMSPCM305P
      * 설명: 급여공제동의 팝업 호출
      *********************************************************/
      fn_OpenMSPCM305P () {
        let lv_vm = this
        this.popup305 = this.$bottomModal.open(MSPCM305P, {
          properties: {
            pPage: 'MSPCM303M',
            targetDate: this.selectedDate, // 대상년월
            targetContents: this.iCCustSrvcMngSbtrAmtVO, // 리스트
            cnsltName: this.cnsltInfo.userNm, // 컨설턴트명//
            targetSarySbtrMsgCntnt: this.sarySbtrMsgCntnt // 코멘트/
          },
          listeners: {
            onPositive: (pData) => {//
              lv_vm.$bottomModal.close(lv_vm.popup305)
              this.fn_InsSarySbtrCnsntYn()
            },
            onNegative: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup305)
            },
          }
        })
      },

      /*********************************************************
      * Function명: fn_OpenMSPCM306P
      * 설명: 프사서 신청
      *********************************************************/
      fn_OpenMSPCM306P: async function (param) {
        let lv_vm = this
        let lv_flag = true
        let from = '';
        let to = '';

        if (this.isServicePoss === false) {
          this.$addSnackbar('지금은 서비스신청 가능 시간이 아닙니다.\n(신청시간 : 08:00 ~ 21:00)')
          return
        }
        lv_vm.selectedCustInfo = (param.custId) ? param : Object.assign([], this.selectItems[0])

        // 1. 기신청한 경우 팝업 출력
        lv_flag = lv_vm.fn_Validate306(lv_vm.selectedCustInfo)

        if (lv_flag === 'date') {
          let msg = {}
          msg.startMonth = lv_vm.srvcAplStrYmd.substring(4, 6)
          msg.startDay = lv_vm.srvcAplStrYmd.substring(6, 8)
          msg.endMonth = lv_vm.srvcAplEndYmd.substring(4, 6)
          msg.endDay = lv_vm.srvcAplEndYmd.substring(6, 8)
          lv_vm.$emit('openPopup', 'alert2', msg)

          from = Number(startMonth) + '월 ' + Number(startDay) + '일'
          to = Number(endMonth) + '월 ' + Number(endDay) + '일'
          
          lv_vm.$addSnackbar('신청기간이 아닙니다.\n신청기간은 ' + from + ' 부터 ' + to + ' 까지 입니다.')
          return
        } else if (lv_flag === 'pass') {
          let isAddPossible = await lv_vm.fn_SelListMyCustAddInfo(lv_vm.selectedCustInfo)
          if (!isAddPossible) {
            lv_vm.$addSnackbar('신청가능 고객 수를 초과하였습니다')
            return
          }
        }

        // 해당 고객의 유형에 대해 서비스 신청이 가능한지 확인
        lv_vm.selectedCustInfo.flag = lv_flag
        lv_vm.selectedCustInfo.objYm = lv_vm.selectedDate
        lv_vm.selectedCustInfo.custSrvcTypCdNm = lv_vm.commCdList.CUST_SRVC_TYP_CD.filter(item => item.cdVal === lv_vm.selectedCustInfo.custSrvcTypCd)[0].cdValNm
        this.popup306 = this.$bottomModal.open(MSPCM306P, {
          
          properties: {
            pPage: 'MSPCM303M',
            selectItems: lv_vm.selectItems,
            selectedCustInfo: lv_vm.selectedCustInfo, // 고객정보
          },
          listeners: {
            onPositive: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup306)
              lv_vm.$refs.nsbottomsheet2.close()
              lv_vm.fn_UpdSrvcAplInfo(pData)
              lv_vm.selectItems = []
              lv_vm.checkedCnt = 0
              lv_vm.isOneOrMoreCheck = false
              lv_vm.$refs.nsbottomsheet2.close()
            },
            onNegative: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.popup306)
            },
          }
        })
      },

      /*********************************************************
      * Function명: fn_OpenMSPCM307P_BS
      * 설명: 결과입력 전 방문결과부실입력금지 팝업 호출
      *********************************************************/
      fn_OpenMSPCM307P_BS () {
        let lv_vm = this
        let popup307_BS = this.$bottomModal.open(MSPCM307P_BS, {
          
          properties: {
            pPage: 'MSPCM303M',
          },
          listeners: {
            onPositive307Bs: () => {
              lv_vm.$bottomModal.close(popup307_BS)
              console.log('--------------------------------------')
              console.log('fn_OpenMSPCM307P_BS onPositive307Bs')
              console.log('--------------------------------------')
              lv_vm.fn_OpenMSPCM307P()
                
            },
            onNegative307Bs: () => {
              console.log('--------------------------------------')
              console.log('fn_OpenMSPCM307P_BS onNegative307Bs')
              console.log('--------------------------------------')
              lv_vm.$bottomModal.close(popup307_BS)
            },
          }
        })
      },

       /*******************************************************
      * function명  : fn_OpenBs1
      * 설명        : sheet open
      ******************************************************/
      fn_OpenBs1() {
        if(this.checkedCnt > 0) return      
       // this.$refs.bottomSheet3.open()
      
      },
      /*********************************************************
      * Function명: fn_OpenMSPCM307P
      * 설명: 결과입력 팝업 호출
      *********************************************************/
      fn_OpenMSPCM307P () {
        let lv_vm = this
        let msg = ''
        lv_vm.selectedCustInfo = this.selectItems[0]
        console.log('lv_vm.selectedCustInfo>>>>', lv_vm.selectedCustInfo)
        msg = lv_vm.fn_Validate307(lv_vm.selectedCustInfo)
        console.log('--------------------------------------')
        console.log('fn_OpenMSPCM307P start')
        console.log('--------------------------------------')
        if (this.isServicePoss === false) {
          console.log('--------------------------------------')
          console.log('this.isServicePoss : true')
          console.log('--------------------------------------')
          lv_vm.$addSnackbar('지금은 서비스신청 가능 시간이 아닙니다.\n(신청시간 : 08:00 ~ 21:00)')
          return
        }
        if (msg !== ''){
          console.log('--------------------------------------')
          console.log('msg : true')
          console.log('--------------------------------------')
           lv_vm.$addSnackbar(msg)
           return
        } 
        lv_vm.selectedCustInfo.custSrvcTypCdNm = lv_vm.commCdList.CUST_SRVC_TYP_CD.filter(item => item.cdVal === lv_vm.selectedCustInfo.custSrvcTypCd)[0].cdValNm
        console.log('--------------------------------------')
        console.log('lv_vm.selectedCustInfo.custSrvcTypCdNm')
        console.log(lv_vm.selectedCustInfo.custSrvcTypCdNm)
        console.log('--------------------------------------')
        console.log('lv_vm.selectedCustInfo>>>>', lv_vm.selectedCustInfo)

        let popup307 = this.$bottomModal.open(MSPCM307P, {
          properties: {
            pPage: 'MSPCM303M',
            selectedCustInfo: lv_vm.selectedCustInfo
          },
          listeners: {
            onPositive307p: () => {
              console.log('--------------------------------------')
              console.log('MSPCM303M CLOSE : onPositive307p')
              console.log('--------------------------------------')
              lv_vm.$bottomModal.close(popup307)
              lv_vm.$refs.nsbottomsheet2.close()
              lv_vm.fn_Btn_S_OnClick()
              lv_vm.selectItems = []
              lv_vm.checkedCnt = 0
              lv_vm.isOneOrMoreCheck = false
            },
            onNegative307p: () => {
              lv_vm.$bottomModal.close(popup307)
              console.log('--------------------------------------')
              console.log('MSPCM303M CLOSE : onNegative307p')
              console.log('--------------------------------------')
            },
          }
        })
      },

      /*******************************************************
      * function명  : fn_OpenCustInfoBs
      * 설명        : 고객상세 팝업 연동
      * param       : String
      * Return      : N/A
      ******************************************************/
      fn_OpenCustInfoBs: function (item) {
        let lv_Vm = this
        let cnsltNo = ''
        let nmUiObject = {}
        console.log('여기타는거?', item)
        // 채널고객ID가 없을 경우 공통이 아닌 화면별 고객등록동의 팝업 표시
        if(this.$bizUtil.isEmpty(item.chnlCustId)) {
          this.fn_OpenInsCustInfo(item)
          return
        }

        if(this.pOrgData303M.cnslt === undefined || this.pOrgData303M.cnslt === null || this.pOrgData303M.cnslt === ''){
            cnsltNo = this.cnsltInfo.userId
          }else{
            cnsltNo = this.pOrgData303M.cnslt.key
          }

        nmUiObject = {
          chnlCustId : item.chnlCustId, //고객채널 ID
          parentId   : 'MSPCM303M', //화면ID+
          cnsltNo : cnsltNo,
          custNm : item.custNm
        }

        // window.vue.getStore('progress').dispatch('FULL')
        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
          //Close 콜백 처리
          return
        })
      },

      

      /******************************************************************************
      * Function명: fn_Validate306
      * 설명: input값에 대한 validation 수행함수
      ******************************************************************************/     
      fn_Validate306: function (param) {
        let lv_vm = this
        let lv_custInfo = param
        let flag = 'pass'
        
        if (lv_custInfo.custSrvcAplYmd.length > 0) {
          if (this.lv_Today !== lv_custInfo.custSrvcAplYmd) {
            flag = 'alrRequested'
          }
        } else if (lv_custInfo.gftNoCd.length > 0 ||
                        lv_custInfo.gftCntnt.length > 0 ||
                              lv_custInfo.srvcInflPathCd.length > 0) {
          flag = 'alrRequested'
        }
        if (flag === 'pass') {
          //데이터 널값
          if (Number(this.lv_Today) < Number(lv_vm.srvcAplStrYmd) || Number(this.lv_Today) > Number(lv_vm.srvcAplEndYmd)) {
            flag = 'date'
          }
        }
        return flag
      },

      /******************************************************************************
      * Function명: fn_Validate307
      * 설명: input값에 대한 validation 수행함수
      ******************************************************************************/     
      fn_Validate307: function (param) {
        let lv_vm = this
        let lv_custInfo = param
        let msg = ''
        if (lv_vm.$bizUtil.isEmpty(lv_custInfo.custSrvcAplYmd)) {
          msg = '서비스 신청을 하지 않은 고객입니다.'

        } else if (lv_custInfo.custSrvcAplYmd === this.lv_Today) {
          msg = '방문(택배)후에 입력해주세요.'
          
        }else if (Number(this.lv_Today) < Number(lv_vm.vstInptStrYmdNow) || Number(this.lv_Today) > Number(lv_vm.vstInptEndYmdNow)) {
          lv_vm.vstFrom = Number(lv_vm.vstInptStrYmdNow.substring(4, 6)) + '월 ' + Number(lv_vm.vstInptStrYmdNow.substring(6, 8)) + '일'
          lv_vm.vstTo = Number(lv_vm.vstInptEndYmdNow.substring(4, 6)) + '월 ' + Number(lv_vm.vstInptEndYmdNow.substring(6, 8)) + '일'
          msg = '결과입력기간이 아닙니다.\n입력기간은' + lv_vm.vstFrom + '부터 ' + lv_vm.vstTo + ' 까지 입니다'
        }
        return msg
      },

      /******************************************************************************
      * Function명 : clickInitial
      * 설명       : 초성 클릭 핸들러
      ******************************************************************************/
      clickInitial (keyword) {
        console.log('초성클릭 핸들러!!')
        this.isInitialCnt++
        if (this.isInitialCnt > 1) return
        this.searchKeyword = this.$cmUtil.getNameInitial(keyword)
        this.isInitialSearch = true
        this.selectItems = []
        this.checkedCnt = 0
        this.isOneOrMoreCheck = false
        this.$refs.nsbottomsheet2.close()
        if (this.currentCnsltInfo.blckTypNm === 'N') {
          // 조회 시작
          this.isDataLoading = true
          
          this.fn_SelListSrvcObjBlckCustTyp()
        }
      },

      /*******************************************************
      * function명  : fn_CtrlBottomSheet
      * 설명        : bottom sheet controll
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_CtrlBottomSheet: function () {
       
        if(this.checkedCnt === 0){
          this.positionUp = false
          this.$refs.nsbottomsheet2.close()
        }else{
          if(this.checkedCnt === 1){
            if(this.fn_Validate306(this.selectItems[0]) === 'alrRequested' || this.fn_Validate306(this.selectItems[0]) === 'pass'){
              this.positionUp = true
              this.$refs.nsbottomsheet2.open()
            }else{
              this.positionUp = false
              this.$refs.nsbottomsheet2.close()
            }
          }else{
            this.positionUp = false
            this.$refs.nsbottomsheet2.close()
          }
        }

        this.isCustCardExist = this.selectItems.filter(item =>(item.chnlCustId.trim() === '')).length === 0

        this.$cmUtil.setSelectedCustList(this, this.selectItems) // 고객 체크 시 바텀시트
      },

      /*******************************************************
      * function명  : fn_CheckboxClick
      * 설명        : 체크박스 클릭
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_CheckboxClick: function () {
        this.selectItems = this.CMCustSrvcObjInfoVOList.filter(item => item.checked === true)
        this.checkedCnt = this.selectItems.length
        
        if(this.CMCustSrvcObjInfoVOList.length === this.checkedCnt){
          this.isOneOrMoreCheck = true
        }else{
          this.isOneOrMoreCheck = false
        }

        // 기신청고객 : 신청이후 하루가 지난 고객 
        if(this.checkedCnt === 1){
          if(this.fn_Validate306(this.selectItems[0]) !== 'alrRequested'){
            this.alrRequested = true
            
          }
          if (this.selectItems[0].custSrvcAplYmd.length > 0) {
            if (this.lv_Today === this.selectItems[0].custSrvcAplYmd) {
              this.hasUpdate = true
            }else{
              this.hasUpdate = false
            }
          }else{
            this.hasUpdate = false
          }
        }
        this.fn_CtrlBottomSheet()
      },

      /*******************************************************
      * function명  : fn_CheckboxClick
      * 설명        : 체크박스 전체 클릭
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_CheckboxAllClick: function () {
        for(let i = 0; i < this.CMCustSrvcObjInfoVOList.length; i++){
            this.CMCustSrvcObjInfoVOList[i].checked = this.isOneOrMoreCheck
        }
        this.CMCustSrvcObjInfoVOList.push()
        if(this.isOneOrMoreCheck){
          this.selectItems = this.CMCustSrvcObjInfoVOList
        }else{
          this.selectItems = []
        }
        this.checkedCnt = this.selectItems.length

        this.fn_CtrlBottomSheet()
      },

      /*******************************************************
      * function명  : fn_DateSelected
      * 설명        : 달력 선택
      * param       : any
      * Return      : N/A
      ******************************************************/
      fn_DateSelected: function (value) {
        this.selectedDate = value.substring(0,4)+value.substring(5,7)
        this.isDateChgYn = true
        this.isTypeCngYn = true
        this.selectItems = []
        this.checkedCnt = 0
        this.$refs.nsbottomsheet2.close()
        this.isOneOrMoreCheck = false
        this.stndKeyVal = 'start'
        this.applyEnd = false
        this.fn_SelListSrvcObjBlckCustTyp()
      },

      /*******************************************************
      * function명  : fn_CustTypeSelected
      * 설명        : 고객유형 선택
      * param       : any
      * Return      : N/A
      ******************************************************/
      fn_CustTypeSelected: function (value) {
        this.isButtonSlct = false
        this.isTypeCngYn = true
        this.selectItems = []
        this.checkedCnt = 0
        this.isOneOrMoreCheck = false
        this.$refs.nsbottomsheet2.close()
        this.selectedCustType = value
        this.applyEnd = false
        this.fn_SelListSrvcObjBlckCustTyp()
      },

      /*******************************************************
      * function명  : fn_GetSarySbtrMsgCntnt
      * 설명        : 메시지 추가
      *               txTSSCM51S5
      * param       : String
      * Return      : String
      ******************************************************/
      fn_GetSarySbtrMsgCntnt: function (sarySbtrMsgCntnt) {
        let lv_vm = this
        if (sarySbtrMsgCntnt === '' || sarySbtrMsgCntnt === null || sarySbtrMsgCntnt === undefined) {
          return '고객사랑 방문서비스 물품 신청시 아래 비용을 부담하는 것에 동의합니다<br>동의하지 않을 경우 신청할 수 없습니다'
        }
        let cnsltNm = lv_vm.cnsltInfo.userNm
        let str = cnsltNm + ' 은/는' + sarySbtrMsgCntnt
        return str
      },

      /******************************************************************************
      * Function명 : fn_GetYn
      * 설명       : 신청여부
      ******************************************************************************/
      fn_GetYn: function (str) {
        let returnValue = '' 
        if(this.$bizUtil.isEmpty(str) === true){
          returnValue = 'N'
        }else{
          returnValue = 'Y'
        }
        return returnValue
      },

      /******************************************************************************
      * Function명 : fn_VstDtlYn
      * 설명       : 방문세부여부
      ******************************************************************************/  
      fn_VstDtlYn: function (custSrvcRsltCd) {
        let returnValue = ''
        let strRsltCd = ''
        if (custSrvcRsltCd) {
          if (custSrvcRsltCd === '3' || custSrvcRsltCd === '4' || custSrvcRsltCd === '5') {
            strRsltCd = '미방문'
          } else {
            strRsltCd = '방문'
          }
        }
        returnValue =  strRsltCd
        // console.log(returnValue)
        return returnValue
      },

      /******************************************************************************
      * Function명 : fn_GetVstDtlYn
      * 설명       : 방문세부여부
      ******************************************************************************/      
      fn_GetVstDtlYn: function (custSrvcProfrYmd, custSrvcRsltCd) {
        let returnValue = ''
        let strRsltCd = ''
        let strProfrYmd = ''
        
        if (custSrvcRsltCd) {
          if (custSrvcRsltCd === '3' || custSrvcRsltCd === '4' || custSrvcRsltCd === '5') {
            strRsltCd = ' (미방문)'
          } else {
            strRsltCd = ' (방문)'
          }
        }
        if (custSrvcProfrYmd) {
          strProfrYmd = this.$cmUtil.frmtDateStr(custSrvcProfrYmd, 'yyyy-mm-dd')
        }
        returnValue = strProfrYmd + strRsltCd

        return returnValue
      },

      /******************************************************************************
      * Function명 : fn_GetCustSrvcTypCd
      * 설명       : 신청여부
      ******************************************************************************/      
      fn_GetCustSrvcTypCd: function (custSrvcTypCd) {
        /*
        let lv_vm = this
        console.log('custSrvcTypCd == ' + custSrvcTypCd)
        lv_vm.custSrvcTypCdList = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD')
        var t = ''
        try {
          if (lv_vm.custSrvcTypCdList.filter(item => item.cdVal === custSrvcTypCd)[0].cdValNm === undefined) {
            t = ''
          } else {
            t = lv_vm.custSrvcTypCdList.filter(item => item.cdVal === custSrvcTypCd)[0].cdValNm
          }
        } catch (e) {
          console.log(e)
        }
        console.log('t == ' + t)
        return t
        */
        var lv_vm = this
        if (lv_vm.DEBUG) console.log('fn_getCustSrvcTypCd')
        lv_vm.custSrvcTypCdList = lv_vm.getStore('code').getters.getState.get('CUST_SRVC_TYP_CD')
        // if (lv_vm.DEBUG)console.log( lv_vm.vipMdemHsplTelCdList)
        // return lv_vm.custSrvcTypCdList[custSrvcTypCd]
        // return lv_vm.custSrvcTypCdList.filter(item => item.cdVal === custSrvcTypCd)[0].cdValNm
        let vCdValNm = custSrvcTypCd
        for (let i in lv_vm.custSrvcTypCdList) {
          if (lv_vm.custSrvcTypCdList[i].cdVal === custSrvcTypCd) {
            vCdValNm = lv_vm.custSrvcTypCdList[i].cdValNm
            break
          }
        }
        return vCdValNm
      },

      /******************************************************************************
      * Function명 : fn_GetInflPathNm
      * 설명       : 코드를 명으로 변경
      ******************************************************************************/
      fn_GetInflPathNm: function (pathCd) {
        let returnValue = ''
        for (let i in this.sInflPathNm ) {
          if (this.sInflPathNm[i].key === pathCd) {
            returnValue = this.sInflPathNm[i].value
            break
          }
        }
        return returnValue + ''
      },
      /******************************************************************************
      * Function명: fn_ServicePoss
      * 설명: 처리가능여부
      ******************************************************************************/
      fn_ServicePoss: function() {
        let lv_vm = this
        let value = ''
        this.$commonUtil.getServerTime().then( function (response) {
          value = moment(response).format('HH')
          if ( Number(value) >= 21 || Number(value) < 8) {
            // 21시 이후 서비스 중지 처리
            lv_vm.isServicePoss = false
          }
        })
      },

      /*******************************************************
      * function명  : fn_HistoryBack
      * 설명        : 뒤로가기 버튼
      * param       : N/A
      * Return      : N/A
      ******************************************************/
      fn_HistoryBack: function () {
        // 탭이 아닌 별도 페이지로 구성되어 store에서 누적 카운트를 한다.
        let cnt = window.vue.getStore('cmStore').getters.getState.viewCntCM303M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM303M', 0)
        if (this.checkedCnt > 0) this.fn_confirm()
        else {
          // 23-05-23 메뉴 리스트에서 선택 가능한 화면에서 back 버튼 선택 시 홈 화면으로 이동 김미정 프로
          // if (this.$route.query?._isMenuItem) {
          //   this.$router.push({name: 'MSPBC002M'})
          //   return
          // }
          this.$router.push({name: 'MSPBC002M'})
          // this.$router.go(-1)
        }
      },

      /*******************************************************
      * function명  : fn_GetTargetMonth
      * 설명        : target Month 구하는 함수
      ******************************************************/
      fn_GetTargetMonth: function () {
        let lv_vm = this
        let t_date = ''
        let returnValue = ''
        if (!lv_vm.selectCheck) {
          returnValue = lv_vm.pSelectParam.objYm
        } else {
          if (lv_vm.month === null || lv_vm.month === '' || lv_vm.month === undefined) {
            t_date = lv_vm.defaultMonth.split('-')
          } else {
            t_date = lv_vm.month.split('-')
          }
          returnValue = t_date[0] + t_date[1]
        }
        return returnValue
      },

      /******************************************************************************
      * Function명 : fn_OpenInsCustInfo
      * 설명       : 고객카드 생성 팝업
      ******************************************************************************/
      fn_OpenInsCustInfo (custInfo) {
        console.log('뭐여', custInfo)
        if (custInfo.rrn === undefined || custInfo.rrn === null || custInfo.rrn === '') {
          this.$showSnackbar('생년월일이 없습니다.')
        } else {
          this.pObjmsg = {
            cnsltNo: this.currentCnsltInfo.userId, // 컨설턴트번호(ZA_CHNL_ID)
            custId: custInfo.custId, // 고객ID(PARTNER)
            custNm: custInfo.custNm, // 고객명
            knb: custInfo.rrn, // 주민번호
            birthDay: this.$bizUtil.addBirthFrontYear(custInfo.rrn.substring(0, 6)), // 생년월일
            gender: (custInfo.sxdsCdNm !== undefined) ? custInfo.sxdsCdNm : this.fn_GetSxdsNmByRrn(custInfo) // 성별
          }

          // console.log('pObjmsg >>>>>', this.pObjmsg)

          // 고객카드생성 바텀시트 오픈
          this.$refs.bottomSheet_CustInfo.open()
        }
      },

      /******************************************************************************
      * Function명 : fn_GetSxdsNmByRrn
      * 설명       : 주민번호로 성별 구하기
      ******************************************************************************/
      fn_GetSxdsNmByRrn (custInfo) {
        let rtnVal = ''
        let sexDist = custInfo.rrn.substring(6,7)

        if (sexDist === '1' || sexDist === '3' || sexDist === '5' || sexDist === '7') {
          rtnVal = '남'
        } else if (sexDist === '2' || sexDist === '4' || sexDist === '6' || sexDist === '8') {
          rtnVal = '여'
        }

        return rtnVal
      },

      /******************************************************************************
       * Function명 : fn_Btn_C_OnClick
       * 설명       : 고객카드생성 취소 버튼
       ******************************************************************************/
      fn_Btn_C_OnClick () {
        // 고객카드생성 바텀시트 닫기
        this.$refs.bottomSheet_CustInfo.close()
      },

      /******************************************************************************
       * Function명 : fn_Btn_I_OnClick
       * 설명       : 고객카드생성 확인 버튼
       ******************************************************************************/
      fn_Btn_I_OnClick () {
        // 지점장 권한일 경우 고객카드생성 불가
        if (this.isMngr !== true) {
          this.fn_InsCustInfo()
        }
      },

      /******************************************************************************
       * Function명 : fn_InsCustInfo
       * 설명       : 통합고객 정보를 채널고객으로 등록
       ******************************************************************************/
      fn_InsCustInfo () {
        const lv_Vm = this
        const trnstId = 'txTSSCM55I1'
        const auth = 'I'

        let confirm = lv_Vm.getStore('confirm')
        let iCNewCustRegVO = lv_Vm.fn_SET_OCC() // 고객 저장 데이터 셋팅
        let pParams = iCNewCustRegVO

        lv_Vm.isLoading = true

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
          // 서버 데이터 가져오기
            if (response.body !== null && response.body !== '' && response.body.chnlCustId !== null && response.body.chnlCustId !== '') {
              // console.log(response.body.chnlCustId)
              // 고객카드 저장 완료 후 바텀시트 닫고
              lv_Vm.$refs.bottomSheet_CustInfo.close()
              lv_Vm.positionUp = false
              lv_Vm.$refs.nsbottomsheet2.close()
              // 리스트 재조회
              lv_Vm.fn_Btn_S_OnClick()
              // 네임UI 오픈
              let custInfo = {
                chnlCustId: response.body.chnlCustId,
                custNm: ''
              }
              lv_Vm.fn_OpenCustInfoBs(custInfo)
            } else {
              confirm.dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
       * Function명 : fn_SET_OCC
       * 설명       : 고객 저장 데이터 셋팅
       ******************************************************************************/
      fn_SET_OCC () {
        let param = {}

        param.prcsfCd = 'I'
        param.condWhlCnt = 0
        param.cnsltNo = this.pObjmsg.cnsltNo
        param.custId = this.pObjmsg.custId
        param.custNm = this.pObjmsg.custNm
        param.knb = this.pObjmsg.knb
        param.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220) *** 신태블릿용 추가 검토 필요
        param.trtSystmPathCd = '22' // 처리시스템경로코드(사랑온 고정 구분값 : 22) *** 신태블릿용 추가 검토 필요
        param.custDtlTypCd = this.fn_USR_getNatyGb(this.pObjmsg.knb) // 고객상세유형코드 셋팅

        if (this.pObjmsg.knb.length > -6) {
          param.chnlCustScCd = '01'
          param.ralDob = this.$bizUtil.addBirthFrontYear(this.pObjmsg.knb.substring(0, 6))
        } else {
          param.chnlCustScCd = '02'
          param.ralDob = ' '
        }

        param.iCNewCustRegListVO = [{
          prcsfCd : param.prcsfCd,
          chnlCustScCd : param.chnlCustScCd,
          custId : param.custId,
          custNm : param.custNm,
          knb : this.pObjmsg.knb,
          cnsltNo : param.cnsltNo,
          ralDob : param.ralDob,
          mnCustRltnCd : "01",
          mnCustRltnCdNm : "본인"
        }]

        return param
      },

      /******************************************************************************
       * Function명 : fn_USR_getNatyGb
       * 설명       : 고객상세유형코드 셋팅
       ******************************************************************************/
      fn_USR_getNatyGb (knb) {
        var natyGb = '11'
        if (knb.length < 7) {
          return natyGb
        }
        var knbGb = knb.substring(6, 7)

        if (knbGb === '1' || knbGb === '2' || knbGb === '3' || knbGb === '4') {
          natyGb = '11'
        } else if (knbGb === '5' || knbGb === '6' || knbGb === '7' || knbGb === '8') {
          natyGb = '12'
        }
        return natyGb
      },

      /******************************************************************************
       * @description confirm popup
       * @param {String} title 제목
       * @param {String} message confirm 내용
       ******************************************************************************/
      fn_confirm(title, message) {
        let lv_Vm = this
        let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              type: 'B',
              content: backMsg,
              title_pos_btn: "예",
              title_neg_btn: "아니오"
            },
            listeners: {
              onPopupConfirm: () => {
                lv_Vm.isOneOrMoreCheck = false
                lv_Vm.fn_CheckboxAllClick()
                lv_Vm.$bottomModal.close(lv_AlertPop)
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              },
              onPopupClose: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
              }
            }
          })
      },

      /******************************************************************************
       * Function명 : fn_OnPageScrollEvent
       * 설명       : 화면 스크롤 컨트롤
       ******************************************************************************/
      fn_OnPageScrollEvent(e) {
        let target = e.target
        if(!target) return
        let sp = target.scrollTop
        this.scrollPosition = sp
      },

      fn_test () {
        this.test = !this.test
      }
    }
  };
</script>
<style>
.ns-insideWidthAuto {
  height: calc(100vh - 56px) !important; 
  width: 100% !important;
}
.list-item__expand {
  z-index: 1 !important;
}
.ns-txt-blue .mo-dropdown__selected span {
  color: #0100ff !important;
}
</style>